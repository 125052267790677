<template>
    <div>
      <Loader/>
      <div class="wrapper">
        <SidebarStyle/>
        <HeaderStyle1/>
        <SubMenu />
        <div class="content-page submenu">
          <transition name="router-anim">
            <router-view/>
          </transition>
        </div>
      </div>
      <FooterStyle />
    </div>
  </template>
  <script>
  import Loader from '@/components/loader/Loader'
  import SidebarStyle from "@/components/partials/backend/SidebarStyle/SidebarStyle";
  import HeaderStyle1 from "@/components/partials/backend/HeaderStyle/HeaderStyle1";
  import FooterStyle from "@/components/partials/backend/FooterStyle/FooterStyle";
  import SubMenu from "@/components/omni/submenu/product";
  export default {
    name: 'Product',
    components: {
      HeaderStyle1,
      FooterStyle,
      SidebarStyle,
      SubMenu,
      Loader
    },
     mounted(){
      document.body.classList=''
    },
    destroyed () {
      document.body.classList=''
    }
    
  }
  </script>
  <style>
  </style>
  