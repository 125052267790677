<template>
    <div class="om-submenu">
          <nav class="om-submenu__nav">
              <ul class="om-submenu__nav">
                  <li :class="checkActive('product.Summary') ? 'active' : ''">
                      <router-link :to="{name: 'product.Summary'}" class="svg-icon">                        
                          <svg  class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line>
                          </svg>
                          <span class="ml-4">Overview</span>
                      </router-link>
                  </li>
                  <li :class="checkActive('product.Media') ? 'active' : ''">
                      <router-link :to="{name: 'product.Media'}" class="svg-icon">                        
                          <svg  class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line>
                          </svg>
                          <span class="ml-4">Media</span>
                      </router-link>
                  </li>
                  <li :class="checkActive('order.FulfilmentList') ? 'active' : ''">
                      <router-link :to="{name: 'order.FulfilmentList'}" class="svg-icon">                        
                          <svg  class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line>
                          </svg>
                          <span class="ml-4">Fitment</span>
                      </router-link>
                  </li>
                  <li :class="checkActive('order.Stripe') ? 'active' : ''">
                      <router-link :to="{name: 'order.Stripe'}" class="svg-icon">                        
                          <svg  class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line>
                          </svg>
                          <span class="ml-4">Stock</span>
                      </router-link>
                  </li>
              </ul>
          </nav>
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex'
  import {core} from '@/config/pluginInit'
  export default {
    name:"SidebarStyle",
    data () {
      return{
      }
    },
    mounted () {
      core.SmoothScrollbar()
      core.changesidebar()
    },
     destroyed () {
      core.SmoothScrollbar()
      core.changesidebar()
    },
    computed : {
          ...mapGetters({
              appName: 'appName',
              logo:'logo'
          })
      },
      methods: {
      checkActive(route) {
          if (this.$route.name == route) {
              return true;
          }
          if (route.includes(this.$route.name)) {
              return true
          }
      }
  }
  
  }
  </script>
<style scoped lang="scss">
.om-submenu{
    position: fixed;
    top: 73px;
    left: 260px;
    right: 0;
    display: flex;
    background: #fff;
    z-index: 1;
    align-items: center;
    height: 65px;
    border-bottom: 1px solid #ddd;
&__nav{
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    margin: 0;
    list-style: none;
    height: 100%;
    li{
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
    &:first-child{
        padding-left: 0;
    }
    &:last-child{
        padding-right: 0;
    }
    &.active{
        border-bottom: 3px solid;
    }
    }
}
}
</style>